import { environment as rivalEnvironment } from './environment.rival';

export const environment = {
  ...rivalEnvironment,

  rivalName: "tropica",
  casinoId: 43,
  GA_TRACKING: "G-X22QY1KX72",

  rivalChatGroupName: "Tropica",
  prettyName: "Tropica Casino",
  contactEmail: "support@tropicacasino.com",
  docsEmail: "documents@tropicacasino.com",

  //SEO
  metaDescription: "Online Casino games at the leader Tropica Casino. Play 300+ slots, the best progressive slots, classic table games: blackjack and roulette, and live dealer gambling. Real cash bonus, welcome bonuses, fast payouts, and secure transaction fast. Join today to claim the VIP loyalty rewards!",
  logoName: "tropica-casino-online-casino-logo.png",
  logoAltText: "Tropica Casino online logo with sunset and palm trees theme popular for slots beach-themed casino games and tropical bonuses."

};


